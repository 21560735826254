.home__container {
    row-gap: 7rem;
}

.home__content {
    grid-template-columns: 116px repeat(2, 1fr);
    padding-top: 5.5rem;
    column-gap: 2rem;
    align-items: center;
}

.home__social {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}

.home__social-icon {
    font-size: 2rem;
    color: var(--title-color-light);
}

.light .home__social-icon {
    color: var(--title-color);
}

.home__social-icon:hover {
    color: var(--light-color);
}

.light .home__social-icon:hover {
    color: var(--text-color);
}

.home__title {
    font-size: var(--huge-font-size);
    margin-bottom: var(--mb-0-25);
}

.home__hand {
    width: 38px;
    height: 38px;
    margin-left: 0.4rem;
}

/* .home__subtitle {
    position: relative;
    font-size: var(--h3-font-size);
    padding-left: 5.4rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}

.home__subtitle::before {
    content: '';
    position: absolute;
    width: 70px;
    height: 1px;
    background-color: var(--text-color);
    left: 0;
    top: 1rem;
} */

.home__description {
    margin-bottom: var(--mb-0-25);
}

.home__img {
    background: url(../../assets/profile.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 12px rgb(255 255 255 / 30%);
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    animation: profile__animate 3.5s ease-in-out infinite, profile_appear 1s;
}

@keyframes profile__animate {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }

    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
        
    }

    100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}

@keyframes profile_appear {
    0% {
        opacity: 0;
        transform: translateX(150px);
        -webkit-filter: blur(2px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
        -webkit-filter: blur(0px);
    }
}

/* Home Intro Animation */
.home__intro {
    height: 310px;
    position: relative;
    max-width: 400px;
}

.text-animate {
    overflow: hidden;
    position: absolute;
    width: 100%;
}

.text-animate-inside {
    padding: 10px 0;
    position: absolute;
}

.text-animate-inside span {
    display: block;
}

.text-top {
    border-bottom: 2px solid var(--text-color);
    height: 55%;
    /* // top: 0; */
}

.light .text-top {
    border-bottom: 2px solid var(--light-color);
}

.text-top-inside {
    animation: showTopText 0.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    bottom: 0;
    transform: translate(0, 100%);
}

.text-bottom {
    height: 45%;
    bottom: 0;
}

.text-bottom-inside {
    animation: showBottomText 1s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    top: 0;
    transform: translate(0, -100%);
}

@keyframes showTopText {
    0% {
        transform: translate3d(0, 100%, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes showBottomText {
    0% {
        transform: translate3d(0, -100%, 0);
    }

    40%,
    70% {
        transform: translate3d(0, -50%, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

/* Home Scroll */

.home__scroll {
    margin-left: 9.25rem;
}

.mouse {
    stroke: var(--title-color-light);
}

.light .mouse {
    stroke: var(--title-color);
}

.wheel {
    animation: scroll 2s ease infinite;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(3.75rem);
    }

}

.home__scroll-name {
    color: var(--title-color-light);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
    margin-left: 2px;
}

.light .home__scroll-name {
    color: var(--title-color);
}

.home__scroll-arrow {
    font-size: 1.25;
    color: var(--title-color-light);
}

.light .home__scroll-arrow {
    color: var(--title-color);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .home__content {
        grid-template-columns: 100px repeat(2, 1fr);
        column-gap: 1.25rem;
    }

    .home__hand {
        width: 26px;
        height: 26px;
    }

    /* .home__subtitle {
        padding-left: 3.75rem;
        margin-bottom: var(--mb-1);
    }

    .home__subtitle::before {
        width: 42px;
        top: 0.8rem;
    } */

    .text-bottom-inside {
        max-width: 350px;
    }

    .home__img {
        width: 250px;
        height: 250px;
        box-shadow: inset 0 0 0 8px rgb(255 255 255 / 30%);
    }

    .home__scroll {
        margin-left: 7.5rem;
    }

    @keyframes showBottomText {
        0% {
            transform: translate3d(0, -100%, 0);
        }
    
        40%,
        70% {
            transform: translate3d(0, -64%, 0);
        }
    
        100% {
            transform: translate3d(0, 0, 0);
        }
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .home__content {
        grid-template-columns: 0.5fr;
        padding-top: 3.5rem;
        text-align: center;
    }

    .home__img {
        order: initial;
        justify-self: initial;
        box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
        width: 200px;
        height: 200px;
    }

    /* .home__subtitle {
        padding-left: 0;
    }

    .home__subtitle::before {
        height: 0;
    } */

    .home__data {
        grid-column: 1/3;
    }

    .contact__button {
        width: 185px;
    }

    .home__intro {
        margin: auto;
        height: 250px;
    }

    .text-top {
        height: 40%;
    }

    .text-bottom {
        height: 60%;
    }


    .text-animate-inside {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .home__social-icon {
        font-size: 1.75rem;
    }

    .home__scroll {
        display: none;
    }
}



/* For small devices */
@media screen and (max-width: 350px) {
    .home__img {
        width: 180px;
        height: 180px;
    }
    
    .home__title {
        font-size: var(--h1-font-size);
    }

    .home__social-icon {
        font-size: 1.5rem;
    }

    .home__hand {
        width: 22px;
        height: 22px;
    }
}