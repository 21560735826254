.about__container {
    grid-template-columns: repeat(2, 1fr);
    /* align-items: center; */
    column-gap: 4rem;
}

/* .about__img {
    width: 350px;
    border-radius: 1.5rem;
    justify-self: center;
} */
.about__data {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 1rem;
}

.about__intro {
    text-align: justify;
    margin-right: 1rem;
    /* width: 440px; */
    /* height: 300px; */
    /* padding-left: 200px; */
    /* align-self: flex-start; */
}

.about__question {
    font-size: var(--huge-font-size);
    line-height: 100%;
    margin-bottom: 15px;
}

.about__answer {
    font-size: var(--big-font-size);
    color: var(--light-color);
}

.light .about__answer {
    color: var(--light-color);
}

.about__text {
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-1);
    align-self: flex-start;
    color: var(--light-color); 
}

.light .about__text {
    color: var(--light-color); 
}

.about__effect {
    color: var(--body-color);
}

.light .about__effect {
    color: var(--title-color);
}

.about__info {
    grid-template-columns: repeat(2, 220px);
    gap: 0.5rem;
    margin-bottom: var(--mb-2);
}

.about__box {
    background-color: var(--title-color);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0.75rem;
    text-align: center;
    padding: 1rem 1.25rem;
}

.light .about__box {
    background-color: var(--container-color);
    border-color: rgba(0, 0, 0, 0.1);
}

.about__icon {
    font-size: 1.5rem;
    color: var(--title-color-light);
    margin-bottom: var(--mb-0-5);
}

.light .about__icon {
    color: var(--title-color);
}

.about__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.about__subtitle {
    font-size: var(--small-font-size);
}

/* .about__description {
    padding: 0 4rem 0 0;
    margin-bottom: var(--mb-2-5);
} */

.about__button {
    justify-self: center;
    grid-column-start: 1;
    grid-column-end: 3;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .about__container {
        grid-template-columns: 1fr;
    }
    .about__button {
        grid-column-start: 1;
        grid-column-end: 2;
    }

    /* .about__img {
        width: 220px;
    } */

    .about__intro {
        order: -1;
        text-align: center;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .about__box {
        padding: 0.75rem 0.5rem;
    }

    .about__data {
        align-items: center;
        margin: 0;
    }

    .about__text {
        width: 420px;
        margin-left: auto;
        margin-right: auto;
    }

    .about__info {
        margin-bottom: var(--mb-0-5);
    }
      
}

@media screen and (max-width: 768px) {
    
}

  /* For medium devices */
@media screen and (max-width: 576px) {
    .about__info {
        grid-template-columns: repeat(2, 1fr);
    }

    /* .about__description{
        padding: 0; 
    } */
    .about__question {
        font-size: 2.2rem;
    }

    .about__answer {
        font-size: var(--h1-font-size);
    }
    
    .about__text {
        max-width: 400px;
        font-size: var(--h1-font-size);
    }
}

@media screen and (max-width: 480px) {
    .about__answer {
        font-size: var(--h2-font-size);
    }
    .about__text {
        width: 100%;
        font-size: var(--h2-font-size);
    }
}

/* For small devices */
@media screen and (max-width: 375px) {
    .about__answer {
        font-size: var(--h3-font-size);
    }
    .about__text {
        font-size: var(--h3-font-size);
    }
}