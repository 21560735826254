.services__container {
    grid-template-columns: repeat(3, 270px);
    justify-content: center;
    column-gap: 1.8rem;
}

.services__content {
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 1.25rem;;
    background-color: var(--title-color);
    padding: 2rem 2rem 2rem 2.5rem;
    transition: transform 0.4s, box-shadow 0.3s;
}

.light .services__content {
    background-color: var(--container-color);
    border-color: rgba(0,0,0, 0.1);
}

.services__content:hover {
    transform: scale(1.02);
    box-shadow: 0px 0px 10px 1px #bfbfbf;
}
  
.services__icon {
    display: block;
    font-size: 2rem;
    color: var(--title-color-light);
    margin-bottom: var(--mb-1);

}

.light .services__icon {
    color: var(--title-color);
}

.services__title {
    font-size: var(--h3-font-size);
    margin-bottom: var(--mb-1);
    font-weight: var(--font-medium);

}


.services__button {
    color: var(--title-color-light);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;
}

.light .services__button {
    color: var(--title-color);
}

.services__button-icon {
    font-size: 1rem;
    transition: 0.3s;
}

.services__button:hover .services__button-icon {
    transform: translateX(0.25rem);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .services__container {
        grid-template-columns: repeat(3, 218px);
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .services__container {
        grid-template-columns: repeat(2,1fr);
    }

    .services__content {
        padding: 2rem 1.5rem 1.5rem 2rem;
    }

    .services__icon {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 576px) {
    .services__container {
        grid-template-columns: 1fr;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    
}
  