.contact__container {
    justify-content: center;
    padding-bottom: 3rem;
}

.contact__form {
    width: 720px;
}



.contact__form-div {
    position: relative;
    margin-bottom: var(--mb-2);
    height: 4rem;
}

.contact__form-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(255, 255, 255, 0.3);
    background: none;
    color: var(--light-color);
    outline: none;
    border-radius: 0.75rem;
    padding: 1.5rem;
    z-index: 1;
}

.light .contact__form-input {
    color: var(--text-color);
    border-color: rgba(0,0,0,0.3);
}

.contact__form-tag {
    position: absolute;
    top: -0.75rem;
    left: 1.25rem;
    font-size: var(--smaller-font-size);
    padding: 0.25rem;
    background-color: var(--title-color-dark);
    z-index: 10;
    user-select: none;
}

.light .contact__form-tag {
    background-color: var(--body-color);
}

.contact__form-area {
    height: 11rem;
}

.contact__form-area textarea {
    resize: none;
}

.send__button {
    width: 190px;
}

.contact__sent-email {
    position: fixed;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--title-color-light);
    padding: .1rem 1rem;
    border-radius: 1rem;
    visibility: hidden;
    opacity: 0;
    transition: visibility .5s, opacity .5s;

}

.light .contact__sent-email {
    background: var(--title-color);
}

.sent {
    visibility: visible;
    opacity: 1;
    transition: opacity .5s;

}

.contact__sent-email span {
    color: var(--title-color-dark);
}

.light .contact__sent-email span {
    color: var(--container-color);
}

.uil-times {
    font-size: var(--h2-font-size);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .contact__form {
        width: 400px;
    }
    .send__button {
        width: 175px;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
}

@media screen and (max-width: 576px) {
    .contact__form {
        margin: 0 auto;
        width: 330px;
    }

}

/* For small devices */
@media screen and (max-width: 350px) {
    .contact__form {
        width: 100%;
    }
}
